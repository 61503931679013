import Link from 'next/link';
import React from 'react';

import Button from '../button';
import styles from './not-found.module.scss';

const NotFound = () => {
  return (
    <div className={styles.pageNotFound}>
      <div className={styles.number}>404</div>
      <p className={styles.info}>
        Oops! The page you are looking for doesn’t exist. Try the button below...
      </p>
      <Link href="/" passHref>
        <a className={styles.buttonBack} aria-label="Back to home">
          <Button>Back to home</Button>
        </a>
      </Link>
    </div>
  );
};

export default NotFound;
